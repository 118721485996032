import "./App.css";
import axios from "axios";
import { useEffect, useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DraftsIcon from "@mui/icons-material/Drafts";
import Snackbar from "@mui/material/Snackbar";

function App() {
  const localServerPath = "http://localhost:4000";
  const devServerPath = "https://api.devops-max.com";
  const [users, setUsers] = useState([]);
  const [history, setHistory] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);

  const getUsers = () => {
    axios.get(devServerPath + "/api/v1/users").then((result) => {
      setUsers(result.data);
    });
  };

  const getHistory = () => {
    axios.get(devServerPath + "/api/v1/history").then((result) => {
      setHistory(result.data);
    });
  };

  const sendPush = (user) => {
    axios.post(devServerPath + "/api/v1/push", {
      userId: user.userId,
      message: "Push 메시지",
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    let polling = setInterval(() => {
      getHistory();
    }, 10000);

    return () => {
      clearInterval(polling);
    };
  }, []);

  return (
    <>
      <List>
        <ListItem
          disablePadding
          style={{
            overflow: "auto",
            width: "50%",
            height: "50vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {users.map((user) => {
            return (
              <ListItemButton
                id={user.userId}
                onClick={() => {
                  sendPush(user);
                  setToastOpen(true);
                }}
              >
                <ListItemIcon>
                  <DraftsIcon />
                </ListItemIcon>
                <ListItemText primary={user.userNickname} />
              </ListItemButton>
            );
          })}
        </ListItem>
      </List>

      <List>
        <ListItem
          disablePadding
          style={{
            overflow: "auto",
            width: "50%",
            height: "50vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {history.map((history) => {
            return (
              <ListItemText
                id={history.notificationId}
                primary={history.userId + " | " + history.message}
                secondary={history.status}
              />
            );
          })}
        </ListItem>
      </List>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={() => {
          setToastOpen(false);
        }}
        message="Push 요청이 완료되었습니다."
      />
    </>
  );
}

export default App;
